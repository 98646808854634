<ng-template>
  <div
    class="sat-popover-container"
    #focusTrapElement
    [ngClass]="_classList"
    [@transformPopover]="{ value: state, params: params }"
    (@transformPopover.done)="_onAnimationDone($event)"
  >
    <mat-card class="mat-elevation-z6 flex flex-col">
      <mat-form-field
        appearance="fill"
        class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain w-full"
      >
        <mat-icon matPrefix>search</mat-icon>
        <input type="text" matInput [formControl]="search" [placeholder]="'reports.filter.usersearch' | translate" />
        <button mat-icon-button matSuffix *ngIf="search?.value" (click)="search.setValue('')">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
      <div class="mt-1 flex flex-row items-center justify-start gap-2">
        <div class="!ml-auto flex flex-row items-center justify-start">
          <div class="cursor-pointer p-2 text-sm font-bold" (click)="this.selectAll()" translate>
            reports.toggles.all
          </div>
          <div class="cursor-pointer p-2 text-sm font-bold" (click)="this.selectNone()" translate>
            reports.toggles.none
          </div>
        </div>
      </div>
      <div class="-mx-16px">
        <mat-divider inset></mat-divider>
      </div>
      <mat-list class="-mx-16px pt-0">
        <cdk-virtual-scroll-viewport [itemSize]="48" class="h-64">
          <mat-list-item
            *cdkVirtualFor="let item of items$filtered.value$ | async; trackBy: trackId"
            (click)="$event.preventDefault(); $event.stopPropagation(); this.selectItem(item.id)"
            (keydown.space)="$event.preventDefault(); $event.stopPropagation(); this.selectItem(item.id)"
            (keydown.enter)="$event.preventDefault(); $event.stopPropagation(); this.close()"
            matRipple
            class="cursor-pointer"
          >
            <div class="flex-mw flex flex-row flex-auto items-center justify-start gap-2">
              <mat-checkbox class="mat-checkbox-flex" [checked]="item.selected" color="primary"></mat-checkbox>
              <span class="mat-truncate flex-auto">{{ item.name }}</span>
              @if (item.subordinate) {
                <div class="size-6 flex-center mat-bg-5 rounded-full">
                  <mat-icon inline [matTooltip]="'workspace.supervisors.subordinates' | translate"
                    >supervisor_account</mat-icon
                  >
                </div>
              }
            </div>
          </mat-list-item>
        </cdk-virtual-scroll-viewport>
      </mat-list>
    </mat-card>
  </div>
</ng-template>
