import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  input,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { distinctUntilChangedJson } from '@app/_helpers/utils';
import { SatPopoverComponent as SatPopover, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, skip } from 'rxjs/operators';
import { UserSettingsQuery } from 'timeghost-api';
import { TIMEZONE_TYPES } from '../reports-export-dialog/reports-export-dialog.component';

export interface AuditFilterData {
  project: boolean;
  task: boolean;
  duration: boolean;
  mod: number;
  modTime: string;
  timezone: 'keep' | 'adjust';
  rulebreaks: boolean;
}
export const AuditFilterDefaultData: AuditFilterData = {
  project: false,
  task: false,
  duration: false,
  mod: 0,
  modTime: '01:00',
  timezone: 'keep',
  rulebreaks: false,
};
@UntilDestroy()
@Component({
  selector: 'tg-audit-filter-popover',
  templateUrl: './audit-filter-popover.component.html',
  styleUrls: ['./audit-filter-popover.component.scss'],
  providers: [
    { provide: SatPopoverAnchoringService, useExisting: false },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuditFilterPopoverComponent),
      multi: true,
    },
  ],
})
export class AuditFilterPopoverComponent extends SatPopover implements OnInit, ControlValueAccessor {
  propagateChange: Function = () => {};
  propagateTouched: Function = () => {};
  group = new FormGroup({
    project: new FormControl(AuditFilterDefaultData.project),
    task: new FormControl(AuditFilterDefaultData.task),
    duration: new FormControl(AuditFilterDefaultData.duration),
    mod: new FormControl(AuditFilterDefaultData.mod),
    modTime: new FormControl(AuditFilterDefaultData.modTime),
    timezone: new FormControl(AuditFilterDefaultData.timezone),
    rulebreaks: new FormControl(AuditFilterDefaultData.rulebreaks),
  });

  readonly timezoneTypes = TIMEZONE_TYPES;
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    _focusTrapFactory: ConfigurableFocusTrapFactory,
    _anchoringService: SatPopoverAnchoringService,
    _viewContainerRef: ViewContainerRef,
    @Optional() @Inject(DOCUMENT) _document: any,
    private cdr: ChangeDetectorRef,
  ) {
    super(_focusTrapFactory, _anchoringService, _viewContainerRef, '200ms cubic-bezier(0.25, 0.8, 0.25, 1)', _document);
    super.openAnimationStartAtScale = super.closeAnimationEndAtScale = 0.9375;
  }
  writeValue(obj: any): void {
    this.group.setValue(obj || AuditFilterDefaultData);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (this.group.disabled !== isDisabled) this.group[isDisabled ? 'disable' : 'enable']();
  }
  readonly comegoTimes = input({ transform: booleanAttribute });

  checkDisabledStates(val: AuditFilterData) {
    const durationModifiers = ['mod', 'modTime'];
    if (durationModifiers.find((x) => val.duration !== this.group.controls[x].enabled))
      durationModifiers
        .filter((cntrl) => val.duration !== this.group.controls[cntrl].enabled)
        .forEach((cntrl) =>
          this.group.controls[cntrl][val.duration ? 'enable' : 'disable']?.({ emitEvent: false, onlySelf: true }),
        );
  }
  ngOnInit(): void {
    this.hasBackdrop = true;
    this.yAlign = 'below';
    this.xAlign = 'center';

    this.group.valueChanges
      .pipe(
        skip(1),
        untilDestroyed(this),
        distinctUntilChangedJson(),
        map(() => this.group.getRawValue()),
      )
      .subscribe((val) => {
        this.checkDisabledStates(val);
        this.propagateChange(val);
        this.propagateTouched(val);
      });
  }
  close() {
    super.close(this.group.value);
  }
}
