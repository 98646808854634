<h1 matDialogTitle>
  <div class="flex items-center">
    <div>{{ 'reports.export' | translate }}</div>
    <div class="flex-auto"></div>
    <div class="flex items-center space-x-2">
      <button mat-icon-button matDialogClose tabindex="-1" [disabled]="closeDisabled">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form (submit)="$event.preventDefault()" [formGroup]="this.group" class="mb-3">
    <div class="flex flex-col items-stretch justify-start gap-2">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'reports.exports.timezone' | translate }}</mat-label>
        <mat-select formControlName="timezone">
          <mat-option [value]="type.value" *ngFor="let type of timezoneTypes; let index = index">{{
            type.name | translate
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.getErrorObservable('timezone', 'reports.exports.timezone') | async as error">{{
          error.content | translate: error.args || {}
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'reports.exports.type' | translate }}</mat-label>
        <mat-select formControlName="exportType">
          <mat-option [value]="index" *ngFor="let value of exportTypes; let index = index">{{
            value.name | translate
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="getErrorObservable('exportType', 'reports.exports.type') | async as error">{{
          error.content | translate: error.args || {}
        }}</mat-error>
      </mat-form-field>
      <div class="flex flex-col pb-5" *ngIf="isExcel(group.value.exportType)">
        <mat-form-field appearance="fill" class="mat-form-field-inline">
          <mat-label>{{ 'reports.export-columns.label' | translate }}</mat-label>
          <mat-select formControlName="columnType">
            <mat-option [value]="0"
              >{{ 'reports.export-columns.all' | translate }} ({{ exportColumns.length }})</mat-option
            >
            <mat-option [value]="1"
              >{{ 'reports.export-columns.custom' | translate }} ({{ selectedExportColumns$ | async }})</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div
          class="rounded-bottom grid grid-cols-1 gap-1 bg-neutral-500/10 px-3.5 pb-4 pt-2 dark:bg-black/10 md:grid-cols-2 lg:grid-cols-3"
          *ngIf="group.value.columnType === 1"
          formGroupName="columns"
        >
          <ng-container *ngFor="let col of exportColumns">
            <mat-checkbox [formControlName]="col" color="primary" class="mat-checkbox-truncate mat-checkbox-flex">
              <span class="truncate">{{ 'reports.export-column.' + col | translate }}</span>
            </mat-checkbox>
          </ng-container>
        </div>
      </div>
      <div class="mat-bg flex items-center space-x-4 rounded px-3.5">
        <mat-slide-toggle
          class="mat-slide-toggle-flex w-full"
          (click.native)="onRoundingHandle()"
          [disabled]="group.controls.rounding?.disabled"
          [checked]="group.value.rounding?.enabled"
          labelPosition="before"
          color="primary"
        >
          <div class="flex h-16 flex-1 items-center space-x-4">
            <div class="flex flex-1 flex-col space-y-0.5">
              <span>{{ 'Rounding' | translate }}</span>
              <div class="mat-typography mat-hint flex items-center space-x-2">
                <mat-icon inline>info</mat-icon>
                <span>{{ 'reports.export-rounding-notice' | translate }}</span>
              </div>
            </div>
            <button
              mat-stroked-button
              *ngIf="group.value.rounding?.enabled"
              [disabled]="group.controls.rounding?.disabled"
              (click.native)="onRoundingHandle(true)"
            >
              {{ 'utils.edit' | translate }}
            </button>
          </div>
        </mat-slide-toggle>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container
    *ngTemplateOutlet="
      !isLoading ? submitButton : submitWaitButton;
      context: { disabled: group.invalid || group.disabled }
    "
  ></ng-container>
</mat-dialog-actions>
<ng-template #submitButton let-disabled="disabled">
  <button mat-flat-button color="primary" (click)="submit()" [disabled]="disabled">
    {{ 'utils.download' | translate }}
  </button>
</ng-template>
<ng-template #submitWaitButton>
  <button mat-flat-button color="primary" [disabled]="true">
    <div class="flex items-center justify-center space-x-2">
      <mat-spinner mode="indeterminate" [diameter]="16" [strokeWidth]="2"></mat-spinner>
      <span>{{ 'utils.download' | translate }}</span>
      <span *ngIf="(progress.value$ | async)?.progress as p">{{ p | percent: 1 : 2 }}</span>
    </div>
  </button>
</ng-template>
