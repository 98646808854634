import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { ExportProgress } from '@app/_helpers/progress-interface';
import { createRxGroupWithCache } from '@app/_helpers/rx-value';
import { createRxValue } from '@app/_helpers/utils';
import { AppService } from '@app/app.service';
import RoundingConfig from '@app/shared/dialogs/rounding-dialog/models/rounding-config';
import RoundingConfigData from '@app/shared/dialogs/rounding-dialog/models/rounding-config-data';
import { RoundingDialogComponent, RoundingTypes } from '@app/shared/dialogs/rounding-dialog/rounding-dialog.component';
import { environment } from '@env/environment';
import { IsBetaWindow } from '@env/version';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { firstValueFrom, map, startWith, switchMap } from 'rxjs';
import { ApplicationSettingsQuery, Logger, TimesService } from 'timeghost-api';
export const TIMEZONE_TYPES = [
  { value: 'keep', name: 'utils.timezone-keep' },
  { value: 'adjust', name: 'utils.timezone-adjust' },
];
const EXPORT_COLUMNS = {
  client: true,
  project: true,
  projectDescription: true,
  task: true,
  description: true,
  author: true,
  staffNumber: true,
  tags: true,
  projectTags: true,
  billable: true,
  start: true,
  end: true,
  timeZone: true,
  sumInHours: true,
  sumInDays: true,
  earnings: true,
  costs: true,
  balance: true,
  currency: true,
  projectValues: true,
  projectAmounts: true,
  projectStatus: true,
};
export const EXPORT_COLUMNS_ARR = <(keyof typeof EXPORT_COLUMNS)[]>Object.keys(EXPORT_COLUMNS);
interface ReportsExportsConfig {
  columns: typeof EXPORT_COLUMNS_ARR;
}
const log = new Logger('ReportsExportDialogComponent');
@ObserveFormGroupErrors()
@UntilDestroy()
@Component({
  selector: 'tg-reports-export-dialog',
  templateUrl: './reports-export-dialog.component.html',
  styleUrls: ['./reports-export-dialog.component.scss'],
})
export class ReportsExportDialogComponent implements OnInit {
  readonly exportTypes = [
    { name: 'utils.excel-download-grouped', groupedByProject: true },
    { name: 'utils.excel-download-simple', groupedByProject: false },
    { name: 'reports.exports.csv-file', groupedByProject: false, csv: true },
    { name: 'reports.exports.pdf-file', groupedByProject: false, pdf: true, beta: true },
    { name: 'reports.exports.pdf-zip-file', groupedByProject: true, pdf: true, beta: true, ext: '.zip' },
  ].filter((x) => {
    if (x.beta && (!environment.production || IsBetaWindow())) return true;
    return !x.beta;
  });
  isExcel(idx: any) {
    if (idx === undefined) return true;
    return !this.exportTypes[idx]?.csv && !this.exportTypes[idx]?.pdf;
  }
  readonly timezoneTypes = TIMEZONE_TYPES;
  readonly group = createRxGroupWithCache(
    new FormGroup({
      timezone: new FormControl('keep', [
        Validators.required,
        (ctrl) =>
          ctrl.value ? (!this.timezoneTypes?.find((x) => x.value === ctrl.value) ? { required: true } : null) : null,
      ]),
      exportType: new FormControl(
        this.exportTypes.findIndex((x) => x.name === 'utils.excel-download-grouped'),
        [Validators.required],
      ),
      rounding: new FormControl<RoundingConfigData>(
        new RoundingConfigData(
          this.data?.filter?.rounding?.enabled
            ? ((rounding) => ({
                enabled: rounding.enabled,
                minutes: rounding.minutes,
                type: Object.entries(RoundingTypes).find(
                  ([key]) => key.toLowerCase() === (rounding.type as any as string),
                )?.[1] as RoundingTypes,
              }))(this.data.filter.rounding)
            : {
                enabled: false,
                minutes: 15,
                type: RoundingTypes.UpTo,
              },
        ),
      ),
      columnType: new FormControl<0 | 1>(0),
      columns: new FormGroup({
        ...EXPORT_COLUMNS_ARR.reduce(
          (acc, r) => {
            acc[r] = new FormControl<boolean>(true);
            return acc;
          },
          {} as { [key: string]: AbstractControl },
        ),
      }),
    }),
    'reports.exportConfig',
  );
  readonly selectedExportColumns$ = this.group.valueChanges.pipe(
    untilDestroyed(this),
    startWith(this.group.value),
    map((d) => {
      const activeColumns = Object.entries(d.columns)
        .filter(([, active]) => active)
        .map(([name]) => name);
      return activeColumns?.length ?? this.exportColumns.length;
    }),
  );
  isLoading: boolean;
  readonly exportColumns = EXPORT_COLUMNS_ARR;
  constructor(
    private timesService: TimesService,
    @Inject(MAT_DIALOG_DATA)
    private data: { filter: { [key: string]: any; startDate: Date; endDate: Date; rounding: RoundingConfigData } },
    private ref: MatDialogRef<ReportsExportDialogComponent>,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private appSettingsQuery: ApplicationSettingsQuery,
    private appService: AppService,
  ) {}
  ngOnInit(): void {
    this.ref.updateSize('680px');
    const exportColumns = this.config?.columns;
    if (exportColumns?.length > 0) {
      this.group.patchValue({
        columnType: 1,
        columns: {
          ...EXPORT_COLUMNS_ARR.reduce((acc, r) => {
            acc[r] = !!exportColumns.find((k) => k === r);
            return acc;
          }, {}),
        },
      });
    }
    this.group.valueChanges
      .pipe(untilDestroyed(this))
      .pipe(
        switchMap(async (value) => {
          if (value.rounding) {
            await this.appService.updateRounding(value.rounding);
          }
        }),
      )
      .subscribe();
  }
  getErrorObservable(controlName: string, langName: string) {
    return useFormErrorObservable(this)(
      controlName,
      () => this.group.controls[controlName],
      {
        required: (error, ctrl) => ({
          content: 'errors.required',
          args: { field: langName },
        }),
      },
      (key) => this.translateService.instant(langName ?? key),
    );
  }
  get config(): ReportsExportsConfig {
    return this.appSettingsQuery.getValue().reports?.export;
  }
  set config(val: ReportsExportsConfig) {
    this.appSettingsQuery.updateByKey('reports.export', val);
  }
  progress = createRxValue<ExportProgress>(null);
  get closeDisabled() {
    return this.ref.disableClose;
  }

  private async exportPDF(filter: any, fileName: string, value: any, groupedByProject: boolean, ext?: string) {
    const apiUrl = '/Create_Pdf_Report';

    const body = {
      startDate: filter.startDate.toISOString(),
      endDate: filter.endDate.toISOString(),
      users: filter.users,
      projects: filter.projects,
      rounding: { enabled: false, minutes: 15, type: 'upto' },
      billable: 'both',
      projectStatus: 'both',
      groupedByProject: groupedByProject,
      timeZone: value.timezone === 'keep' ? 'KEEP' : 'ADJUST',
    };

    try {
      const response = await firstValueFrom(
        this.timesService.adapter.post<Blob>(apiUrl, body, {
          responseType: 'blob' as 'json',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );

      this.progress.value = {
        finished: true,
        loaded: response.size,
        percent: 1,
        total: response.size,
      };
      saveAs(response, `${fileName}${ext ?? `.${groupedByProject ? 'zip' : 'pdf'}`}`);
      return response;
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  }

  private generateRandomString(): string {
    return Math.random().toString(36).substring(2, 15);
  }

  async submit() {
    const value = this.group.value;
    this.isLoading = true;
    this.ref.disableClose = true;
    this.group.disable();
    const filter = this.data.filter;

    const fileName = `timeghost_${filter.startDate.toLocaleDateString()}-${filter.endDate.toLocaleDateString()}`;
    const exportType =
      this.exportTypes[value.exportType] ?? ({ groupedByProject: true, csv: false, pdf: false } as any);

    if (exportType.pdf) {
      try {
        await this.exportPDF(filter, fileName, value, exportType.groupedByProject, exportType.ext);
        this.ref.close();
      } catch (error) {
        console.error('Error generating PDF:', error);
        this.isLoading = false;
        this.ref.disableClose = false;
        this.group.enable();
      }
    } else {
      const exportColumns = !exportType?.csv && value.columnType === 1 ? value.columns : undefined;
      this.config = {
        columns: exportColumns
          ? Object.entries(exportColumns)
              .filter(([key, value]) => value)
              .map(([key]) => key)
          : [],
      } as ReportsExportsConfig;

      (
        (exportType.csv ? this.timesService.downloadCsvReport : this.timesService.downloadExcelReport).bind(
          this.timesService,
        )({
          ...((filter as any) ?? {}),
          columns: exportColumns,
          timeZone:
            {
              keep: 'KEEP',
              adjust: 'ADJUST',
            }[value.timezone] ?? 'ADJUST',
          ...(() => {
            if (!exportType.csv) return { groupedByProject: exportType.groupedByProject };
            return {};
          })(),
          rounding: value.rounding
            ? {
                ...value.rounding,
                type: {
                  [RoundingTypes.DownTo]: 'downto',
                  [RoundingTypes.UpTo]: 'upto',
                  [RoundingTypes.Nearest]: 'nearest',
                }[value.rounding.type]!,
              }
            : { enabled: false },
        }) as Promise<Blob>
      )
        .finally(() => {
          this.ref.disableClose = false;
        })
        .then((blob: Blob) => {
          log.debug(blob.type);
          this.progress.value = { finished: true, loaded: blob.size, percent: 1, total: blob.size };
          saveAs(blob, fileName + (exportType.csv ? '.csv' : '.xlsx'));
          this.ref.close();
        })
        .catch((err: any) => {
          log.error(err);
          this.isLoading = false;
          this.group.enable();
        });
    }
  }
  onRoundingHandle(forceUpdate?: boolean) {
    const { rounding } = this.group.value;
    if (!rounding.enabled || forceUpdate)
      this.dialog
        .open(RoundingDialogComponent, {
          data: new RoundingConfig({
            data: rounding ? { ...rounding, enabled: true } : null,
            hideToggle: true,
          }),
        })
        .afterClosed()
        .toPromise()
        .then((newConfig) => {
          if (newConfig) {
            this.group.patchValue({
              rounding: { ...newConfig },
            });
          }
        });
    else if (rounding.enabled && !forceUpdate) {
      this.group.patchValue({
        rounding: { ...rounding, enabled: false },
      });
    }
  }
}
