import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';

@Component({
  selector: 'app-reports-download-dialog',
  templateUrl: './reports-download-dialog.component.html',
  styleUrls: ['./reports-download-dialog.component.scss'],
})
export class ReportsDownloadDialogComponent implements OnInit {
  constructor(
    private router: Router,
    private ref: MatDialogRef<ReportsDownloadDialogComponent>,
    private app: AppService,
  ) {}

  ngOnInit() {
    this.ref.updateSize('430px');
    this.ref.addPanelClass('mat-dialog-panel-full');
  }
  isTeams() {
    return this.app.isTeams();
  }
}
