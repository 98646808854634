import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { TranslateModule } from '@ngx-translate/core';
import { EmployeeFilterPopoverComponent } from './employee-filter-popover.component';

@NgModule({
  declarations: [EmployeeFilterPopoverComponent],
  imports: [
    CommonModule,
    SatPopoverModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,

    ScrollingModule,
    TranslateModule,
  ],
  exports: [EmployeeFilterPopoverComponent],
})
export class EmployeeFilterPopoverModule {}
