import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { ExportProgress } from '@app/_helpers/progress-interface';
import { createRxValue } from '@app/_helpers/utils';
import { AppService } from '@app/app.service';
import RoundingConfigData from '@app/shared/dialogs/rounding-dialog/models/rounding-config-data';
import { RoundingTypes } from '@app/shared/dialogs/rounding-dialog/rounding-dialog.component';
import { environment } from '@env/environment';
import { IsBetaWindow } from '@env/version';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { combineLatest, firstValueFrom, map, startWith, switchMap } from 'rxjs';
import { ApplicationSettingsQuery, ComegoService, Logger, TimesService, UserSettingsQuery } from 'timeghost-api';

export const TIMEZONE_TYPES = [
  { value: 'keep', name: 'utils.timezone-keep' },
  { value: 'adjust', name: 'utils.timezone-adjust' },
];

const EXPORT_COLUMNS = {
  client: true,
  project: true,
  projectDescription: true,
  task: true,
  description: true,
  author: true,
  staffNumber: true,
  tags: true,
  projectTags: true,
  billable: true,
  start: true,
  end: true,
  timeZone: true,
  sumInHours: true,
  sumInDays: true,
  earnings: true,
  costs: true,
  balance: true,
  currency: true,
  projectValues: true,
  projectAmounts: true,
  projectStatus: true,
};

export const EXPORT_COLUMNS_ARR = <(keyof typeof EXPORT_COLUMNS)[]>Object.keys(EXPORT_COLUMNS);

interface ScheduleExportsConfig {
  columns: typeof EXPORT_COLUMNS_ARR;
}

const log = new Logger('ScheduleExportDialogComponent');

@ObserveFormGroupErrors()
@UntilDestroy()
@Component({
  selector: 'tg-schedule-export-dialog',
  templateUrl: './schedule-export-dialog.component.html',
  styleUrls: ['./schedule-export-dialog.component.scss'],
})
export class ScheduleExportDialogComponent implements OnInit {
  constructor(
    private ref: MatDialogRef<ScheduleExportDialogComponent>,
    private appSettingsQuery: ApplicationSettingsQuery,
    private appService: AppService,
    private translateService: TranslateService,
    private timesService: TimesService,
    private comegoService: ComegoService,
    private userSettingsQuery: UserSettingsQuery,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      filter: {
        endDate: any;
        startDate: any;
        rounding: any;
        users: string[];
        groups: string[];
      };
      comego: boolean;
    },
  ) {}

  isLoading: boolean = false;
  readonly exportTypes: {
    name: string;
    groupedByProject?: boolean;
    csv?: boolean;
    pdf?: boolean;
    beta?: boolean;
  }[] = [
    { name: 'comego.export.excel-download-grouped', groupedByProject: true },
    { name: 'comego.export.pdf-file', groupedByProject: false, pdf: true, beta: true },
    { name: 'comego.export.pdf-zip-file', groupedByProject: true, pdf: true, beta: true },
  ].filter((x) => {
    if (x.beta && (!environment.production || IsBetaWindow())) return true;
    return !x.beta;
  });

  readonly data$ = combineLatest([this.userSettingsQuery.select()]).pipe(
    map(([userSettings]) => {
      const { users } = this.data.filter;
      return users.map((id) => userSettings.workspace.users.find((x) => x.id == id)).filter(Boolean);
    }),
  );

  isExcel(idx: any) {
    if (idx === undefined) return true;
    return !this.exportTypes[idx]?.csv && !this.exportTypes[idx]?.pdf;
  }

  readonly timezoneTypes = TIMEZONE_TYPES;
  readonly group = new FormGroup({
    timezone: new FormControl('keep', [
      Validators.required,
      (ctrl) =>
        ctrl.value ? (!this.timezoneTypes?.find((x) => x.value === ctrl.value) ? { required: true } : null) : null,
    ]),
    exportType: new FormControl(0, [Validators.required]),
    rounding: new FormControl<RoundingConfigData>(
      new RoundingConfigData(
        this.data?.filter?.rounding?.enabled
          ? ((rounding) => ({
              enabled: rounding.enabled,
              minutes: rounding.minutes,
              type: Object.entries(RoundingTypes).find(
                ([key]) => key.toLowerCase() === (rounding.type as any as string),
              )?.[1] as RoundingTypes,
            }))(this.data.filter.rounding)
          : {
              enabled: false,
              minutes: 15,
              type: RoundingTypes.UpTo,
            },
      ),
    ),
    columnType: new FormControl<0 | 1>(0),
    columns: new FormGroup({
      ...EXPORT_COLUMNS_ARR.reduce(
        (acc, r) => {
          acc[r] = new FormControl<boolean>(true);
          return acc;
        },
        {} as { [key: string]: AbstractControl },
      ),
    }),
  });

  readonly selectedExportColumns$ = this.group.valueChanges.pipe(
    untilDestroyed(this),
    startWith(this.group.value),
    map((d) => {
      const activeColumns = Object.entries(d.columns)
        .filter(([, active]) => active)
        .map(([name]) => name);
      return activeColumns?.length ?? this.exportColumns.length;
    }),
  );

  readonly exportColumns = EXPORT_COLUMNS_ARR;

  ngOnInit(): void {
    this.ref.updateSize('680px');
    const exportColumns = this.config?.columns;

    if (exportColumns?.length > 0) {
      this.group.patchValue({
        columnType: 1,
        columns: {
          ...EXPORT_COLUMNS_ARR.reduce((acc: any, r) => {
            acc[r] = !!exportColumns.find((k) => k === r);
            return acc;
          }, {}),
        },
      });
    }
    this.group.valueChanges
      .pipe(untilDestroyed(this))
      .pipe(
        switchMap(async (value) => {
          if (value.rounding) {
            await this.appService.updateRounding(value.rounding);
          }
        }),
      )
      .subscribe();
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.appSettingsQuery.updateByKey('project.search', filterValue);
    }
  }

  getErrorObservable(controlName: string, langName: string) {
    return useFormErrorObservable(this)(
      controlName,
      () => this.group.controls[controlName],
      {
        required: (error, ctrl) => ({
          content: 'errors.required',
          args: { field: langName },
        }),
      },
      (key) => this.translateService.instant(langName ?? key),
    );
  }

  get config(): ScheduleExportsConfig {
    return this.appSettingsQuery.getValue().schedule?.export;
  }

  set config(val: ScheduleExportsConfig) {
    this.appSettingsQuery.updateByKey('schedule.export', val);
  }

  progress = createRxValue<ExportProgress>(null);

  get closeDisabled() {
    return this.ref.disableClose;
  }

  private async exportPDF(filter: any, fileName: string, value: any, groupedByProject: boolean) {
    const apiUrl = '/Create_Pdf_Report_Sollstunden';

    const body = {
      startDate: filter.startDate.toISOString(),
      endDate: filter.endDate.toISOString(),
      users: filter.users,
      groupedByProject: groupedByProject,
      rounding: { enabled: false, minutes: 15, type: 'upto' },
      billable: 'both',
      projectStatus: 'both',

      timeZone: value.timezone === 'keep' ? 'KEEP' : 'ADJUST',
    };

    try {
      const response = await firstValueFrom(
        this.timesService.adapter.post<Blob>(apiUrl, body, {
          responseType: 'blob' as 'json',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );

      this.progress.value = {
        finished: true,
        loaded: response.size,
        percent: 1,
        total: response.size,
      };
      saveAs(response, `${fileName}.${groupedByProject ? 'zip' : 'pdf'}`);
      return response;
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  }

  private getUserName(userId: string): string {
    const userSettings = this.userSettingsQuery.getValue();
    const user = userSettings.workspace.users.find((u) => u.id === userId);
    return user ? `${user.name}` : this.generateRandomString();
  }

  private generateRandomString(): string {
    return Math.random().toString(36).substring(2, 15);
  }

  async submit() {
    const value = this.group.value;
    const user = this.userSettingsQuery.getValue();
    this.isLoading = true;
    this.ref.disableClose = true;
    this.group.disable();
    const filter = this.data.filter;
    const fileName = `timeghost_${filter.startDate.toLocaleDateString()}-${filter.endDate.toLocaleDateString()}`;
    const exportType = this.exportTypes[value.exportType] ?? {
      groupedByProject: true,
      csv: false,
      pdf: false,
      pdfSplit: false,
    };

    if (exportType.pdf) {
      try {
        await this.exportPDF(filter, fileName, value, exportType.groupedByProject);
        this.ref.close();
      } catch (error) {
        console.error('Error generating PDF:', error);
        this.isLoading = false;
        this.group.enable();
      }
    } else {
      const exportColumns = !exportType?.csv && value.columnType === 1 ? value.columns : undefined;
      const comego = this.data.comego && !!user.workspace.settings?.comego;
      const service = comego ? this.comegoService : this.timesService;
      this.config = {
        columns: exportColumns
          ? Object.entries(exportColumns)
              .filter(([key, value]) => value)
              .map(([key]) => key)
          : [],
      } as ScheduleExportsConfig;
      (
        (exportType.csv ? service.downloadCsvReport : service.downloadExcelReport).bind(service)({
          ...((filter as any) ?? {}),
          columns: exportColumns,
          groupedByProject: true,
          timeZone:
            {
              keep: 'KEEP',
              adjust: 'ADJUST',
            }[value.timezone] ?? 'ADJUST',

          ...(() => {
            if (!comego && !exportType.csv) return { groupedByProject: exportType.groupedByProject };
            return {};
          })(),
          rounding: value.rounding
            ? {
                ...value.rounding,
                type: {
                  [RoundingTypes.DownTo]: 'downto',
                  [RoundingTypes.UpTo]: 'upto',
                  [RoundingTypes.Nearest]: 'nearest',
                }[value.rounding.type]!,
              }
            : { enabled: false },
        }) as Promise<Blob>
      )
        .finally(() => {
          this.ref.disableClose = false;
        })
        .then((blob: Blob) => {
          log.debug(blob.type);
          this.progress.value = {
            finished: true,
            loaded: blob.size,
            percent: 1,
            total: blob.size,
          };
          saveAs(blob, fileName + (exportType.csv ? '.csv' : '.xlsx'));
          this.ref.close();
        })
        .catch((err: any) => {
          log.error(err);
          this.isLoading = false;
          this.group.enable();
        });
    }
  }
}
