<h1 matDialogTitle>
  <div class="flex items-center">
    <div>{{ 'reports.export' | translate }}</div>
    <div class="flex-auto"></div>
    <div class="flex items-center space-x-2">
      <button mat-icon-button matDialogClose tabindex="-1" [disabled]="closeDisabled">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="this.group"
    class="mb-3 flex flex-col items-stretch justify-start gap-2"
  >
    <mat-form-field appearance="fill">
      <mat-label>{{ 'reports.exports.type' | translate }}</mat-label>
      <mat-select formControlName="exportType">
        <mat-option [value]="index" *ngFor="let value of exportTypes; let index = index">{{
          value.name | translate
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="getErrorObservable('exportType', 'reports.exports.type') | async as error">{{
        error.content | translate: error.args || {}
      }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container
    *ngTemplateOutlet="
      !isLoading ? submitButton : submitWaitButton;
      context: { disabled: group.invalid || group.disabled }
    "
  ></ng-container>
</mat-dialog-actions>
<ng-template #submitButton let-disabled="disabled">
  <button mat-flat-button color="primary" (click)="submit()" [disabled]="disabled">
    {{ 'utils.download' | translate }}
  </button>
</ng-template>
<ng-template #submitWaitButton>
  <button mat-flat-button color="primary" [disabled]="true">
    <div class="flex items-center justify-center space-x-2">
      <mat-spinner mode="indeterminate" [diameter]="16" [strokeWidth]="2"></mat-spinner>
      <span>{{ 'utils.download' | translate }}</span>
      <span *ngIf="(progress.value$ | async)?.progress as p">{{ p | percent: 1 : 2 }}</span>
    </div>
  </button>
</ng-template>
