<mat-dialog-content>
  <div class="flex flex-col">
    <div class="--headerLogo-wrapper">
      <svg
        width="54"
        height="47"
        viewBox="0 0 54 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="--headerLogo-logo"
      >
        <path
          d="M17.796 45.1128C31.107 48.8708 40.157 43.7748 45.04 40.0368C49.923 36.2978 54.785 28.4758 53.002 29.0148C42.274 32.2578 39.846 27.2108 40.358 19.9808C40.617 16.3288 41.302 10.7818 39.793 7.2928C38.283 3.8038 33.963 0.667796 23.703 0.0617962C11.267 -0.673204 3.48001 5.29579 1.72601 10.1208C-0.0279906 14.9458 -0.238991 20.2388 0.190009 24.3488C0.619009 28.4578 4.48501 41.3548 17.796 45.1128Z"
        />
        <path
          d="M32.419 14.3578C32.092 11.8618 30.68 9.9898 29.265 10.1748C27.851 10.3598 27.271 12.5808 27.598 15.0758C27.924 17.5708 29.033 19.3968 30.448 19.2108C31.863 19.0258 32.745 16.8528 32.419 14.3578Z"
          fill="white"
        />
        <path
          d="M24.668 15.0388C24.532 12.2938 22.9 10.1438 21.022 10.2368C19.145 10.3298 17.733 12.6308 17.869 15.3758C18.005 18.1208 19.637 20.2698 21.515 20.1768C23.392 20.0838 24.804 17.7838 24.668 15.0388Z"
          fill="white"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 18 18"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="--headerLogo-icon"
      >
        <g>
          <rect style="width: 100%; height: 100%; stroke: none" rx="50%" />
        </g>
        <path
          d="M12.4425 8.5H11.25V4.75C11.25 4.3375 10.9125 4 10.5 4H7.5C7.0875 4 6.75 4.3375 6.75 4.75V8.5H5.5575C4.89 8.5 4.5525 9.31 5.025 9.7825L8.4675 13.225C8.76 13.5175 9.2325 13.5175 9.525 13.225L12.9675 9.7825C13.44 9.31 13.11 8.5 12.4425 8.5Z"
          style="stroke: none; fill: #fff"
        />
      </svg>
    </div>
    <div class="flex flex-col">
      <h3 class="mat-h3 text-center">
        {{ 'report.downloading-file' | translate }}
      </h3>
      <p class="mat-typography" *ngIf="isTeams()">
        {{ 'report.downloading-file-teams' | translate }}
      </p>
      <div class="mt-4 flex flex-row flex-wrap items-center justify-center gap-2">
        <button mat-button mat-dialog-close>
          {{ 'utils.close' | translate }}
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
