<ng-template>
  <div
    class="sat-popover-container"
    #focusTrapElement
    [ngClass]="_classList"
    [@transformPopover]="{ value: state, params: params }"
    (@transformPopover.done)="_onAnimationDone($event)"
  >
    <mat-card class="mat-elevation-z6 flex w-72 flex-col space-y-4" [formGroup]="group">
      <h3 class="text-base font-bold" translate>reports.filter-bar.audit</h3>
      <div class="flex flex-col space-y-2">
        @if (!comegoTimes()) {
          <mat-checkbox formControlName="project" color="primary">{{ 'project.none' | translate }}</mat-checkbox>
          <mat-checkbox formControlName="task" color="primary">{{ 'task.none' | translate }}</mat-checkbox>
        } @else {
          <mat-checkbox formControlName="rulebreaks" color="primary">{{
            'supervisor.rulebreaks' | translate
          }}</mat-checkbox>
        }
      </div>
      @if (!comegoTimes()) {
        <ng-container *ngTemplateOutlet="divider"></ng-container>
        <div class="flex flex-col">
          <mat-checkbox formControlName="duration" color="primary">{{
            'task.duration.byduration' | translate
          }}</mat-checkbox>
        </div>
        <div class="items-between flex space-x-4">
          <div class="flex flex-auto flex-col">
            <h3 class="mb-2 text-base font-bold" translate>task.duration.entries</h3>
            <mat-radio-group formControlName="mod" class="flex flex-col space-y-2">
              <mat-radio-button [value]="0" color="primary">{{ 'task.duration.longer' | translate }}</mat-radio-button>
              <mat-radio-button [value]="1" color="primary">{{ 'task.duration.shorter' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="flex flex-col wflex-[0_0_100px]">
            <h3 class="mb-2 text-base font-bold" translate>task.duration.than</h3>
            <tg-duration-input-control formControlName="modTime" [displayFormat]="false" placeholder="00:00">
              <ng-template
                durationInputControl
                let-disabled="disabled"
                let-control="control"
                let-inputPlaceholder="inputPlaceholder"
              >
                <mat-form-field
                  fxFill
                  [matTooltip]="inputPlaceholder | translate"
                  [matTooltipDisabled]="disabled"
                  appearance="fill"
                  class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain text-center"
                >
                  <input type="text" matInput [formControl]="control" />
                </mat-form-field>
              </ng-template>
            </tg-duration-input-control>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="divider"></ng-container>
        <div class="flex flex-col">
          <mat-form-field appearance="fill" class="mat-form-field-no-hint mat-form-field-plain">
            <mat-label>{{ 'reports.exports.timezone' | translate }}</mat-label>
            <mat-select formControlName="timezone">
              <mat-option [value]="type.value" *ngFor="let type of timezoneTypes; let index = index">{{
                type.name | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      }
    </mat-card>
  </div>
</ng-template>
<ng-template #divider>
  <div class="-mx-16px my-2">
    <mat-divider inset></mat-divider>
  </div>
</ng-template>
